body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace !important;
  background: black;
  color: whitesmoke;
  padding: 3px 0px;
  font-weight: 600;
}

.text-primary {
  color: #123B64 !important;
  font-weight: bold !important;
  size: 24px !important;
}

a {
  text-decoration: none !important;
  color: unset !important;
}

a:hover {
  text-decoration: underline !important;
}

.metric-card {
  background-color: white;
  -webkit-box-shadow: 13px 13px 29px 8px rgba(196, 196, 196, 1);
  -moz-box-shadow: 13px 13px 29px 8px rgba(196, 196, 196, 1);
  box-shadow: 13px 13px 29px 8px rgba(196, 196, 196, 1);
  min-height: 150px;
  border-radius: 8px;
}

.jsoneditor-react-container {
  width: 100%;
  height: 100%;
}

.responsive-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}